import { FALLBACK_MESSAGE, isFalsyString, sentenceCase } from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import carsharingRoutes from '@domains/Carsharing/router/CarsharingRouterMap';
import fleetRoutes from '@domains/Carsharing/router/FleetRouterMap';
import { CARSHARING_PERMISSIONS } from '@domains/Carsharing/const/permissions';
import { FLEET_PERMISSIONS } from '@domains/Fleet/const/permissions';
import crm from '@domains/CRM/router/CRMRouterMap';
import { CRM_PERMISSIONS } from '@domains/CRM/const/permissions';
import { BookingStatusTemplate } from '@/templates';
import { PermissionLink } from '@/components';

export const contentCells = [
  {
    attributeName: 'id',
    title: 'ID',
    displayPriority: 1,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: !isFalsyString(result.uuid) ? {
        name: carsharingRoutes.bookings.detail.index,
        params: {
          bookingUuid: result.uuid,
        },
      } : null,
      linkPermissions: [CARSHARING_PERMISSIONS.viewCarsharingBookings],
      text: result.id ? `#${result.id}` : FALLBACK_MESSAGE.dash,
    }),
  },
  {
    title: 'Date',
    displayPriority: 1,
    type: RECORD_TYPES.template,
    minWidth: 120,
    template: result => `${result.start} <br/> ${result.end}`,
  },
  {
    attributeName: 'usage_status',
    title: 'Booking status',
    displayPriority: 1,
    minWidth: 180,
    type: RECORD_TYPES.component,
    component: BookingStatusTemplate,
    props: result => ({ result }),
  },
  {
    title: 'User',
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: crm.users.detail.bookings,
        params: {
          userUuid: result.user_uuid,
        },
      },
      linkPermissions: [CRM_PERMISSIONS.viewCrmUsers],
      text: result.user_name,
    }),
  },
  {
    title: 'Vehicle',
    attributeName: 'vehicle_uuid',
    displayPriority: 1,
    type: RECORD_TYPES.component,
    component: PermissionLink,
    props: result => ({
      classLink: 'emobg-link-primary emobg-body-2',
      to: {
        name: fleetRoutes.vehicles.detail.details,
        params: {
          vehicleUuid: result.vehicle_uuid,
        },
      },
      linkPermissions: [FLEET_PERMISSIONS.viewCarsharingVehicles],
      text: `${result.vehicle_brand} ${result.vehicle_model} - ${result.vehicle_plate}`,
    }),
    minWidth: 100,
  },
  {
    title: 'Location',
    type: RECORD_TYPES.template,
    template: result => `${result.city} <br/> ${result.location}`,
    displayPriority: 1,
    minWidth: 150,
  },
];

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Profile',
      attributeName: 'profile',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Status',
      attributeName: 'status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Usage status',
      attributeName: 'usage_status',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Booking type',
      attributeName: 'booking_type',
      transformValue: sentenceCase,
    },
  },
];
